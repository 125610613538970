import React, { Component } from 'react'
import * as styles from './style.module.css'
import {Link} from 'gatsby'
import {
	CarouselProvider,
	Dot,
	Slide,
	Slider,
	ButtonBack,
	ButtonNext,
	Image
} from "pure-react-carousel"
import 'pure-react-carousel/dist/react-carousel.es.css'

import arrowleft from "../../images/home/arrow-left.png"
import arrowright from "../../images/home/arrow-right.png"
import image_button from "./buttonplaceholder.png"

class SlideShow extends Component {
	state = {
		moving:false //for disabling automatic animation while user is navigating slideshow so that they won't double jump
	}

	disableAnimation = () => {
		this.setState({moving : !this.state.moving})
		setTimeout( ( ()=>this.setState({moving : !this.state.moving}) ), 2000)
	}


	render() {
		const {
			imagelist,
			sliderlist,
			wdhtratio,
			imgwdhtratio,
			cutoffwdhtratio,
			children
		}	= this.props
		const moving = this.state.moving
		return (
			<div className={wdhtratio>cutoffwdhtratio ? styles.slidewide:styles.slidetall }>
				<CarouselProvider
					naturalSlideWidth={imgwdhtratio}
					naturalSlideHeight={1}
					totalSlides={imagelist.length}
					isPlaying={!moving}
					interval={5000}
					className={styles.container}
					infinite={true}
				>
					<Slider >
						{imagelist.map((entry) =>
							<Slide index={entry.node.id} >
								<Image src={entry.node.fluid.originalImg} isBgImage={true}  />
								{sliderlist[entry.node.fluid.originalName] && <div class={styles.slidecontent}>
									{ sliderlist[entry.node.fluid.originalName].title &&
										<div class={`${styles.slidetitle} font-size-large font-style-title`} dangerouslySetInnerHTML={{__html:sliderlist[entry.node.fluid.originalName].title}}/>
									}
									{ sliderlist[entry.node.fluid.originalName].subtitle &&
										<div class={`${styles.slidesubtitle} font-size-medium`}>{sliderlist[entry.node.fluid.originalName].subtitle}</div>
									}
									{ sliderlist[entry.node.fluid.originalName].button &&
										<Link to={sliderlist[entry.node.fluid.originalName].buttonurl} className={`${styles.slidebutton} font-size-medium color-background-primary`}>
											{sliderlist[entry.node.fluid.originalName].button}
										</Link>
									}

								</div>}
							</Slide>
						)}
					</Slider>
					<ButtonBack
						className={`${styles.buttonBack} ${styles.button}`}
						onClick={this.disableAnimation}
					>
						<img src={arrowleft} alt='<'/>
					</ButtonBack>
					<ButtonNext
						className={`${styles.buttonNext} ${styles.button}`}
						onClick={this.disableAnimation}
					>
						<img src={arrowright} alt='>'/>
					</ButtonNext>
					<div
						className={`${styles.dotGroup}`}
					>
						{imagelist.map((data, index) =>
							<Dot slide={index}>
								<img className={styles.buttonplaceholder} src={image_button} alt=''/>
							</Dot>
						)}

						{children?children:null}
					</div>
				</CarouselProvider>
			</div>
		)
	}
}
export default SlideShow

