import React, { Component } from "react"
import { graphql, Link } from "gatsby" // to query for image data
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SlideShow from '../components/slideshow'

import * as styles from '../styles/pages/index.module.css'

import sliderlist from "../content/sliderlist.json"

const image_selections = "../images/home/selections.png"
const image_selections_mobile = "../images/home/mobile-selections.png"


export default class MainIndex extends Component {

	state = {
		width: 1024,
		height: 600
	}

	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		this.setState(state => ({
			width: window.innerWidth,
			height: window.innerHeight
		}));
	};

	render() {
		const mobilewdhtratio = 1104/1545
		const desktopwdhtratio = 2048/1545

		const wdhtratio= (this.state.width/this.state.height)
		const imgwdhtratio= (wdhtratio>=desktopwdhtratio)?desktopwdhtratio:mobilewdhtratio
		return (
			<Layout activePath='' pageTitle="Home">
				<SlideShow cutoffwdhtratio={desktopwdhtratio} imgwdhtratio={imgwdhtratio} wdhtratio={wdhtratio} sliderlist={sliderlist} imagelist={imgwdhtratio===desktopwdhtratio ? this.props.data.desktop.edges : this.props.data.mobile.edges}>
					<div>
						<div class={styles.navarea}>
							<StaticImage layout="fullWidth" src={image_selections} alt='' class="fullblock-desktop-only" />
							<StaticImage layout="fullWidth" src={image_selections_mobile} alt='' class="fullblock-mobile-only" />
							<Link to={"/howitworks"} className={styles.howitworks} />
							<Link to={"/certified"} className={styles.certified} />
							<Link to={"/about/#howtouse"} className={styles.howtouse} />
							<Link to={"/faq"} className={styles.faq} />
						</div>
					</div>
				</SlideShow>
			</Layout>
		)
	}

}


export const data = graphql`
	query sliderPhotos {
		desktop: allImageSharp(
			filter: {fluid: {originalName: {glob: "desktop-slider*"}}}
			sort: { fields: [ fixed___originalName], order: ASC}
		){
			edges {
				node {
					fluid {
						originalImg
						originalName
					}
					id
				}
			}
		}
		mobile: allImageSharp(
			filter: {fluid: {originalName: {glob: "mobile-slider*"}}}
			sort: { fields: [ fixed___originalName], order: ASC}
		){
			edges {
				node {
					fluid {
						originalImg
						originalName
					}
					id
				}
			}
		}
	}
`

